import { graphql } from 'gatsby'

import { PageLayout } from '../components/Layout'
import { Trustpilot } from '../components/Trustpilot'
import Hero from '../components/Hero/Hero'
import { TextAndMediaLayout } from '../components/TextAndMedia'
import { LinkboxCarousel } from '../components/LinkboxCarousel'
import { FootballCalendar } from '../components/FootballCalendar'
import { Faq } from '../components/FAQ'

const FootballTeamPage = ({ data: { content } }) => {
  const {
    hero,
    footballCalendar,
    trustpilotRef,
    textAndMediaContentReferencesCollection,
    linkboxSliderReference,
    textAndMediaContentReference,
    awardTextAndImageReference,
    faqSection,
    sys,
  } = content.page

  return (
    <PageLayout>
      {hero?.contentRef && <Hero {...hero.contentRef} />}
      <FootballCalendar {...footballCalendar} />
      {trustpilotRef && (
        <Trustpilot
          {...trustpilotRef}
          template="footballTeamPage"
          id={sys.id}
          item="trustpilotRef"
        />
      )}
      {textAndMediaContentReferencesCollection?.items &&
        textAndMediaContentReferencesCollection.items.map((elem, index) => {
          return <TextAndMediaLayout key={index} {...elem} />
        })}
      <LinkboxCarousel {...linkboxSliderReference} />
      {textAndMediaContentReference && (
        <TextAndMediaLayout
          mediaWidth={{ md: 8 }}
          reverse
          {...textAndMediaContentReference}
        />
      )}
      {awardTextAndImageReference && (
        <TextAndMediaLayout
          mediaWidth={{ md: 8 }}
          {...awardTextAndImageReference}
        />
      )}
      {faqSection?.itemsCollection?.items &&
        faqSection?.itemsCollection?.items.length !== 0 && (
          <Faq
            {...faqSection.itemsCollection}
            heading={faqSection?.heading}
            urlSlug={faqSection?.urlSlug?.slug}
            defaultExpanded={faqSection?.defaultExpanded ?? false}
          />
        )}
    </PageLayout>
  )
}

export const query = graphql`
  query FootballTeamPageQuery(
    $id: String!
    $locale: String!
    $preview: Boolean = false
  ) {
    content: contentful {
      page: footballTeamPage(id: $id, locale: $locale, preview: $preview) {
        sys {
          id
        }
        hero: heroReference {
          ... on contentful_PaxHeroLayout {
            contentRef {
              ...PaxHeroContent
            }
          }
        }
        footballCalendar {
          ...FootballCalendar
        }
        trustpilotRef {
          ...Trustpilot
        }
        textAndMediaContentReferencesCollection {
          items {
            ...TextAndMediaLayout
          }
        }
        linkboxSliderReference {
          ...LinkboxCarousel
        }
        textAndMediaContentReference {
          ...TextAndMediaContent
        }
        awardTextAndImageReference {
          ...TextAndMediaContent
        }
        faqSection {
          ...FaqFragment
        }
      }
    }
  }
`

export default FootballTeamPage
